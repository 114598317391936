<template>
  <div id="app">
    <Framework/>
  </div>
</template>

<script>
  import Vue from "vue"
  import Framework from "./components/Framework.vue"

  Vue.component("Framework", Framework)

  export default class App extends Vue {}
</script>

<style scoped>
  body {
      touch-action: initial !important;
  }

  @import "./style/fontawesome.css"; 
  @import "./style/bootstrap.css";
  @import "./style/vegabyte.css";
</style>