<template>
    <div>
        <section class="hero-privacy py-5">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-8">
                        <h2 class="text-center text-light">Datenschutzerklärung</h2>
                    </div>
                    <div class="col-md-4">
                        <img :src="require('@/assets/logo.png')" alt="Logo" class="img-fluid rounded-circle">
                    </div>
                </div>
            </div>
        </section>

        <section class="privacy py-5 bg-dark">
            <div class="container">
                <div class="row">
                    <div>
                        <h2>
                            <span style="font-size: 12pt;" class="text-gold-light"><strong>1. Datenschutz auf einen Blick</strong></span>
                        </h2>
                        <h3>
                            <span style="font-size: 13px;"><strong>Allgemeine Hinweise</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Die folgenden Hinweise geben einen einfachen Überblick
                                darüber, was mit Ihren
                                personenbezogenen Daten passiert, wenn Sie unsere Website besuchen. Personenbezogene
                                Daten sind alle
                                Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen
                                zum Thema
                                Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
                                Datenschutzerklärung.</span></p>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    Datenerfassung auf unserer Website</strong></span>
                        </h3>
                        <p><strong><span style="font-size: 13px;">Wer ist verantwortlich für die Datenerfassung auf
                                    dieser
                                    Website?</span></strong></p>
                        
                        <p><span style="font-size: 13px;">Die Datenverarbeitung auf dieser Website erfolgt durch den
                                Websitebetreiber.
                                Dessen Kontaktdaten können Sie dem Impressum dieser Website entnehmen.</span></p>
                        
                        <p><strong><span style="font-size: 13px;">Wie erfassen wir Ihre Daten?</span></strong></p>
                        
                        <p><span style="font-size: 13px;">Ihre Daten werden zum einen dadurch erhoben, dass Sie uns
                                diese mitteilen.
                                Hierbei kann es sich z.B. um Daten handeln, die Sie in ein Kontaktformular
                                eingeben.</span></p>
                        
                        <p><span style="font-size: 13px;">Andere Daten werden automatisch beim Besuch der Website durch
                                unsere
                                IT-Systeme erfasst. Das sind vor allem technische Daten (z.B. Internetbrowser,
                                Betriebssystem oder
                                Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie
                                unsere Website
                                betreten.</span></p>
                        
                        <p><strong><span style="font-size: 13px;">Wofür nutzen wir Ihre Daten?</span></strong></p>
                        
                        <p><span style="font-size: 13px;">Ein Teil der Daten wird erhoben, um eine fehlerfreie
                                Bereitstellung der
                                Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens
                                verwendet
                                werden.</span></p>
                        
                        <p><strong><span style="font-size: 13px;">Welche Rechte haben Sie bezüglich Ihrer
                                    Daten?</span></strong></p>
                        
                        <p><span style="font-size: 13px;">Sie haben jederzeit das Recht unentgeltlich Auskunft über
                                Herkunft, Empfänger
                                und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem
                                ein Recht, die
                                Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren
                                Fragen zum
                                Thema Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse
                                an uns wenden.
                                Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde
                                zu.</span></p>
                        
                        <p><span style="font-size: 13px;">Außerdem haben Sie das Recht, unter bestimmten Umständen die
                                Einschränkung der
                                Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Details hierzu entnehmen Sie
                                der
                                Datenschutzerklärung unter „Recht auf Einschränkung der Verarbeitung“.</span></p>
                        <h2>
                            <strong><span style="font-size: 12pt;" class="text-gold-light">
                                    2. Allgemeine Hinweise und Pflichtinformationen</span></strong><span
                                style="font-size: 13px;"><strong></strong></span>
                        </h2>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    Datenschutz</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Die Betreiber dieser Seiten nehmen den Schutz Ihrer
                                persönlichen Daten sehr
                                ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der
                                gesetzlichen
                                Datenschutzvorschriften sowie dieser Datenschutzerklärung.</span></p>
                        
                        <p><span style="font-size: 13px;">Wenn Sie diese Website benutzen, werden verschiedene
                                personenbezogene Daten
                                erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert
                                werden können. Die
                                vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie
                                nutzen. Sie
                                erläutert auch, wie und zu welchem Zweck das geschieht.</span></p>
                        
                        <p><span style="font-size: 13px;">Wir weisen darauf hin, dass die Datenübertragung im Internet
                                (z.B. bei der
                                Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der
                                Daten vor dem
                                Zugriff durch Dritte ist nicht möglich.</span></p>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    Hinweis zur verantwortlichen Stelle</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Die verantwortliche Stelle für die Datenverarbeitung auf
                                dieser Website
                                ist:</span></p>
                        
                        <span style="font-size: 13px;">Vegabyte</span></br>
                        <span style="font-size: 13px;">Markus Brünner</span>
                        <p><span style="font-size: 13px;">
                                <a href="mailto:contact@vegabyte.de">contact@vegabyte.de</a></span></br>
                        <p><span style="font-size: 13px;">Verantwortliche Stelle ist die natürliche oder juristische
                                Person, die allein
                                oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von
                                personenbezogenen Daten (z.B.
                                Namen, E-Mail-Adressen o. Ä.) entscheidet.</span></p>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    Widerruf Ihrer Einwilligung zur Datenverarbeitung</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Viele Datenverarbeitungsvorgänge sind nur mit Ihrer
                                ausdrücklichen
                                Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit
                                widerrufen. Dazu reicht
                                eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf
                                erfolgten
                                Datenverarbeitung bleibt vom Widerruf unberührt.</span></p>
                        <h3>
                            <span style="font-size: 13px;">
                                Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung
                                (Art. 21
                                DSGVO)</span>
                        </h3>
                        <p><strong><span style="font-size: 13px;">Wenn die Datenverarbeitung auf Grundlage von Art. 6
                                    Abs. 1 lit. e oder
                                    f DSGVO erfolgt, haben Sie jederzeit das Recht, aus Gründen, die sich aus Ihrer
                                    besonderen Situation
                                    ergeben, gegen die Verarbeitung Ihrer personenbezogenen Daten Widerspruch
                                    einzulegen; dies gilt auch
                                    für ein auf diese Bestimmungen gestütztes Profiling. Die jeweilige Rechtsgrundlage,
                                    auf denen eine
                                    Verarbeitung beruht, entnehmen Sie dieser Datenschutzerklärung. Wenn Sie Widerspruch
                                    einlegen,
                                    werden wir Ihre betroffenen personenbezogenen Daten nicht mehr verarbeiten, es sei
                                    denn, wir können
                                    zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen,
                                    Rechte und
                                    Freiheiten überwiegen oder die Verarbeitung dient der Geltendmachung, Ausübung oder
                                    Verteidigung von
                                    Rechtsansprüchen (Widerspruch nach Art. 21 Abs. 1 DSGVO).</span></strong></p>
                        
                        <p><strong><span style="font-size: 13px;">Werden Ihre personenbezogenen Daten verarbeitet, um
                                    Direktwerbung zu
                                    betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung Sie
                                    betreffender
                                    personenbezogener Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für
                                    das Profiling,
                                    soweit es mit solcher Direktwerbung in Verbindung steht. Wenn Sie widersprechen,
                                    werden Ihre
                                    personenbezogenen Daten anschließend nicht mehr zum Zwecke der Direktwerbung
                                    verwendet (Widerspruch
                                    nach Art. 21 Abs. 2 DSGVO).</span></strong></p>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    Beschwerderecht bei der zuständigen Aufsichtsbehörde</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Im Falle von Verstößen gegen die DSGVO steht den Betroffenen
                                ein
                                Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
                                gewöhnlichen
                                Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das
                                Beschwerderecht
                                besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher
                                Rechtsbehelfe.</span></p>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    Recht auf Datenübertragbarkeit</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
                                Einwilligung oder in
                                Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in
                                einem gängigen,
                                maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der
                                Daten an einen
                                anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar
                                ist.</span></p>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    Auskunft, Sperrung, Löschung und Berichtigung</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
                                jederzeit das
                                Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren
                                Herkunft und
                                Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung,
                                Sperrung oder
                                Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten
                                können Sie sich
                                jederzeit unter der im Impressum angegebenen Adresse an uns wenden.</span></p>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    Recht auf Einschränkung der Verarbeitung</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
                                personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit unter der im
                                Impressum
                                angegebenen Adresse an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht
                                in folgenden
                                Fällen:</span></p>
                        <ul>
                            <li><span style="font-size: 13px;">Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
                                    personenbezogenen
                                    Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die
                                    Dauer der Prüfung
                                    haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen
                                    Daten zu
                                    verlangen.</span></li>
                            <li><span style="font-size: 13px;">Wenn die Verarbeitung Ihrer personenbezogenen Daten
                                    unrechtmäßig geschah
                                    / geschieht, können Sie statt der Löschung die Einschränkung der Datenverarbeitung
                                    verlangen.</span>
                            </li>
                            <li><span style="font-size: 13px;">Wenn wir Ihre personenbezogenen Daten nicht mehr
                                    benötigen, Sie sie
                                    jedoch zur Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen
                                    benötigen, haben Sie das
                                    Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen
                                    Daten zu
                                    verlangen.</span></li>
                            <li><span style="font-size: 13px;">Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO
                                    eingelegt haben,
                                    muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange
                                    noch nicht
                                    feststeht, wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der
                                    Verarbeitung
                                    Ihrer personenbezogenen Daten zu verlangen.</span></li>
                        </ul>
                        <p><span style="font-size: 13px;">Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
                                eingeschränkt haben,
                                dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder
                                zur
                                Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der
                                Rechte einer anderen
                                natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen
                                Interesses der
                                Europäischen Union oder eines Mitgliedstaats verarbeitet werden.</span></p>
                        <h2>
                            <span style="font-size: 12pt;" class="text-gold-light"><strong>3. Datenerfassung auf unserer Website</strong></span>
                        </h2>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    Cookies</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Die Internetseiten verwenden teilweise so genannte Cookies.
                                Cookies richten
                                auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu,
                                unser Angebot
                                nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien,
                                die auf Ihrem
                                Rechner abgelegt werden und die Ihr Browser speichert.</span></p>
                        
                        <p><span style="font-size: 13px;">Die meisten der von uns verwendeten Cookies sind so genannte
                                “Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere
                                Cookies bleiben auf
                                Ihrem Endgerät gespeichert bis Sie diese löschen. Diese Cookies ermöglichen es uns,
                                Ihren Browser beim
                                nächsten Besuch wiederzuerkennen.</span></p>
                        
                        <p><span style="font-size: 13px;">Sie können Ihren Browser so einstellen, dass Sie über das
                                Setzen von Cookies
                                informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für
                                bestimmte Fälle
                                oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des
                                Browser
                                aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website
                                eingeschränkt
                                sein.</span></p>
                        
                        <p><span style="font-size: 13px;">Cookies, die zur Durchführung des elektronischen
                                Kommunikationsvorgangs oder
                                zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorbfunktion)
                                erforderlich
                                sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der
                                Websitebetreiber hat ein
                                berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und
                                optimierten
                                Bereitstellung seiner Dienste. Soweit andere Cookies (z.B. Cookies zur Analyse Ihres
                                Surfverhaltens)
                                gespeichert werden, werden diese in dieser Datenschutzerklärung gesondert
                                behandelt.</span></p>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    Server-Log-Dateien</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Der Provider der Seiten erhebt und speichert automatisch
                                Informationen in so
                                genannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies
                                sind:</span></p>
                        <ul>
                            <li><span style="font-size: 13px;">Browsertyp und Browserversion</span></li>
                            <li><span style="font-size: 13px;">verwendetes Betriebssystem</span></li>
                            <li><span style="font-size: 13px;">Referrer URL</span></li>
                            <li><span style="font-size: 13px;">Hostname des zugreifenden Rechners</span></li>
                            <li><span style="font-size: 13px;">Uhrzeit der Serveranfrage</span></li>
                            <li><span style="font-size: 13px;">IP-Adresse</span></li>
                        </ul>
                        <p><span style="font-size: 13px;">Eine Zusammenführung dieser Daten mit anderen Datenquellen
                                wird nicht
                                vorgenommen.</span></p>
                        
                        <p><span style="font-size: 13px;">Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6
                                Abs. 1 lit. f
                                DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der technisch fehlerfreien
                                Darstellung und
                                der Optimierung seiner Website – hierzu müssen die Server-Log-Files erfasst
                                werden.</span></p>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    Kontaktformular</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Wenn Sie uns per Kontaktformular Anfragen zukommen lassen,
                                werden Ihre Angaben
                                aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks
                                Bearbeitung der
                                Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
                                nicht ohne Ihre
                                Einwilligung weiter.</span></p>
                        
                        <p><span style="font-size: 13px;">Die Verarbeitung der in das Kontaktformular eingegebenen Daten
                                erfolgt somit
                                ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können
                                diese
                                Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an
                                uns. Die
                                Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom
                                Widerruf
                                unberührt.</span></p>
                        
                        <p><span style="font-size: 13px;">Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben
                                bei uns, bis
                                Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der
                                Zweck für die
                                Datenspeicherung entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
                                Zwingende gesetzliche
                                Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.</span></p>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    Registrierung auf dieser Website</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Sie können sich auf unserer Website registrieren, um
                                zusätzliche Funktionen
                                auf der Seite zu nutzen. Die dazu eingegebenen Daten verwenden wir nur zum Zwecke der
                                Nutzung des
                                jeweiligen Angebotes oder Dienstes, für den Sie sich registriert haben. Die bei der
                                Registrierung
                                abgefragten Pflichtangaben müssen vollständig angegeben werden. Anderenfalls werden wir
                                die
                                Registrierung ablehnen.</span></p>
                        
                        <p><span style="font-size: 13px;">Für wichtige Änderungen etwa beim Angebotsumfang oder bei
                                technisch
                                notwendigen Änderungen nutzen wir die bei der Registrierung angegebene E-Mail-Adresse,
                                um Sie auf diesem
                                Wege zu informieren.</span></p>
                        
                        <p><span style="font-size: 13px;">Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b
                                DSGVO, der die
                                Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen
                                gestattet.</span>
                        </p>
                        
                        <p><span style="font-size: 13px;">Die bei der Registrierung erfassten Daten werden von uns
                                gespeichert, solange
                                Sie auf unserer Website registriert sind und werden anschließend gelöscht. Gesetzliche
                                Aufbewahrungsfristen bleiben unberührt.</span></p>
                        
                        <p><span style="font-size: 13px;"><strong>Datenschutzerklärung Google Analytics</strong></span>
                        </p>
                        <p><strong><span style="font-size: 13px;"><br></span></strong></p>
                        <p><span style="font-size: 13px;">Unsere Website nutzt den Analysedienst Google Analytics,
                                betrieben von Google
                                Inc. 1600 Amphitheatre Parkway Mountain View, CA 94043, USA. Google Analytics nutzt
                                “Cookies”, das sind
                                kleine Textdateien, die in Ihrem Browser gespeichert werden und die es ermöglichen, die
                                Nutzung unserer
                                Website durch die Besucher zu analysieren. Von den Cookies werden Daten über Ihre
                                Nutzung unserer
                                Webseite gesammelt, die normalerweise an einen Google-Server in den USA übertragen und
                                gespeichert
                                werden.</span></p>
                        <p><span style="font-size: 13px;">Wenn die IP-Anonymisierung auf unserer Webseite aktiviert
                                wurde, wird Ihre
                                IP-Adresse von Google innerhalb der Mitgliedstaaten der Europäischen Union oder in
                                anderen
                                Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vorab gekürzt. In
                                seltenen
                                Ausnahmefällen kann die komplette IP-Adresse an einen Google Server in den USA
                                übertragen werden, dann
                                wird diese dort gekürzt. Google nutzt diese Daten in unserem Auftrag, um die Nutzung
                                unserer Website
                                auszuwerten, um Berichte über die Webseitenaktivitäten zu erstellen sowie um weitere
                                Dienstleistungen
                                anzubieten, die mit der Webseitennutzung und Internetnutzung zusammenhängen. Die von
                                Google Analytics
                                erfassten IP-Adressen werden nicht mit anderen Daten von Google korreliert.</span></p>
                        <p><span style="font-size: 13px;">Die Speicherung von Cookies kann durch eine spezielle
                                Einstellung in Ihrem
                                Browser verweigert werden. In diesem Fall ist jedoch die Funktionalität unserer Webseite
                                im vollen
                                Umfang nicht gewährleistet. Zusätzlich steht ihnen ein Browser-Plugin zu Verfügung, mit
                                dem Sie die
                                Sammlung der auf Ihre Nutzung der Website bezogenen erzeugten Daten und IP-Adressen
                                durch Google
                                verhindern können. Mehr Informationen dazu finden Sie hier: <a
                                    href="http://tools.google.com/dlpage/gaoptout?hl=de">http://tools.google.com/dlpage/gaoptout?hl=de</a></span>
                        </p>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    Registrierung mit Facebook Connect</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Statt einer direkten Registrierung auf unserer Website können
                                Sie sich mit
                                Facebook Connect registrieren. Anbieter dieses Dienstes ist die Facebook Ireland
                                Limited, 4 Grand Canal
                                Square, Dublin 2, Irland.</span></p>
                        
                        <p><span style="font-size: 13px;">Wenn Sie sich für die Registrierung mit Facebook Connect
                                entscheiden, werden
                                Sie automatisch auf die Plattform von Facebook weitergeleitet. Dort können Sie sich mit
                                Ihren
                                Nutzungsdaten anmelden. Dadurch wird Ihr Facebook-Profil mit unserer Website bzw.
                                unseren Diensten
                                verknüpft. Durch diese Verknüpfung erhalten wir Zugriff auf Ihre bei Facebook
                                hinterlegten Daten. Dies
                                sind vor allem:</span></p>
                        <ul>
                            <li><span style="font-size: 13px;">Facebook-Name</span></li>
                            <li><span style="font-size: 13px;">Facebook-Profilbild</span></li>
                            <li><span style="font-size: 13px;">bei Facebook hinterlegte E-Mail-Adresse</span></li>
                            <li><span style="font-size: 13px;">Facebook-ID</span></li>
                            <li><span style="font-size: 13px;">Geburtstag</span></li>
                            <li><span style="font-size: 13px;">Geschlecht</span></li>
                            <li><span style="font-size: 13px;">Land</span></li>
                        </ul>
                        <p><span style="font-size: 13px;">Diese Daten werden zur Einrichtung, Bereitstellung und
                                Personalisierung Ihres
                                Accounts genutzt.</span></p>
                        
                        <p><span style="font-size: 13px;">Die Registrierung mit Facebook-Connect und die damit
                                verbundenen
                                Datenverarbeitungsvorgänge erfolgen auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit.
                                a DSGVO). Diese
                                Einwilligung können Sie jederzeit mit Wirkung für die Zukunft widerrufen.</span></p>
                        
                        <p><span style="font-size: 13px;">Weitere Informationen finden Sie in den
                                Facebook-Nutzungsbedingungen und den
                                Facebook-Datenschutzbestimmungen. Diese finden Sie unter: <a
                                    href="https://de-de.facebook.com/about/privacy/">https://de-de.facebook.com/about/privacy/</a>
                                und
                                <a
                                    href="https://de-de.facebook.com/legal/terms/">https://de-de.facebook.com/legal/terms/</a>.</span>
                        </p>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    Registrierung mit Google</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Statt einer direkten Registrierung auf unserer Website können
                                Sie sich mit
                                Google registrieren. Anbieter dieses Dienstes ist die Google Ireland Limited, Gordon
                                House, Barrow
                                Street, Dublin 4, Irland.</span></p>
                        
                        <p><span style="font-size: 13px;">Wenn Sie sich für die Registrierung mit Google entscheiden,
                                werden Sie
                                automatisch auf die Plattform von Google weitergeleitet. Dort können Sie sich mit Ihren
                                Nutzungsdaten
                                anmelden. Dadurch wird Ihr Google-Profil mit unserer Website bzw. unseren Diensten
                                verknüpft. Durch
                                diese Verknüpfung erhalten wir Zugriff auf Ihre bei Google hinterlegten Daten. Dies sind
                                vor
                                allem:</span></p>
                        <ul>
                            <li><span style="font-size: 13px;">Google-Profilbild</span></li>
                            <li><span style="font-size: 13px;">bei Google hinterlegte E-Mail-Adresse</span></li>
                            <li><span style="font-size: 13px;">Google-ID</span></li>
                            <li><span style="font-size: 13px;">Geburtstag</span></li>
                            <li><span style="font-size: 13px;">Geschlecht</span></li>
                            <li><span style="font-size: 13px;">Land</span></li>
                        </ul>
                        <p><span style="font-size: 13px;">Diese Daten werden zur Einrichtung, Bereitstellung und
                                Personalisierung Ihres
                                Accounts genutzt.</span></p>
                        
                        <p><span style="font-size: 13px;">Die Registrierung mit Google und die damit verbundenen
                                Datenverarbeitungsvorgänge erfolgen auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit.
                                a DSGVO). Diese
                                Einwilligung können Sie jederzeit mit Wirkung für die Zukunft widerrufen.</span></p>
                        
                        <p><span style="font-size: 13px;">Weitere Informationen finden Sie in den
                                Google-Nutzungsbedingungen,
                                Google-Nutzungsbedingungen und den Google-Datenschutzbestimmungen. Diese finden Sie
                                unter: <a
                                    href="https://policies.google.com/terms?hl=de">https://policies.google.com/terms?hl=de</a>,
                                <a
                                    href="https://www.google.com/intl/de/+/policy/pagesterms.html">https://www.google.com/intl/de/+/policy/pagesterms.html</a>
                                und <a
                                    href="https://policies.google.com/privacy?hl=de">https://policies.google.com/privacy?hl=de</a>.</span>
                        </p>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    Registrierung mit Twitter</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Statt einer direkten Registrierung auf unserer Website können
                                Sie sich mit
                                Twitter registrieren. Anbieter dieses Dienstes ist die Twitter Inc., 1355 Market Street,
                                Suite 900, San
                                Francisco, CA 94103, USA.</span></p>
                        
                        <p><span style="font-size: 13px;">Wenn Sie sich für die Registrierung mit Twitter entscheiden,
                                werden Sie
                                automatisch auf die Plattform von Twitter weitergeleitet. Dort können Sie sich mit Ihren
                                Nutzungsdaten
                                anmelden. Dadurch wird Ihr Twitter-Profil mit unserer Website bzw. unseren Diensten
                                verknüpft. Durch
                                diese Verknüpfung erhalten wir Zugriff auf Ihre bei Twitter hinterlegten Daten. Dies
                                sind vor
                                allem:</span></p>
                        <ul>
                            <li><span style="font-size: 13px;">Twitter-Name</span></li>
                            <li><span style="font-size: 13px;">Twitter-Profilbild</span></li>
                            <li><span style="font-size: 13px;">bei Twitter hinterlegte E-Mail-Adresse</span></li>
                            <li><span style="font-size: 13px;">Twitter-ID</span></li>
                            <li><span style="font-size: 13px;">Geburtstag</span></li>
                            <li><span style="font-size: 13px;">Geschlecht</span></li>
                            <li><span style="font-size: 13px;">Land</span></li>
                        </ul>
                        <p><span style="font-size: 13px;">Diese Daten werden zur Einrichtung, Bereitstellung und
                                Personalisierung Ihres
                                Accounts genutzt.</span></p>
                        
                        <p><span style="font-size: 13px;">Die Registrierung mit Twitter und die damit verbundenen
                                Datenverarbeitungsvorgänge erfolgen auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit.
                                a DSGVO). Diese
                                Einwilligung können Sie jederzeit mit Wirkung für die Zukunft widerrufen.</span></p>
                        
                        <p><span style="font-size: 13px;">Weitere Informationen finden Sie in den
                                Twitter-Nutzungsbedingungen und den
                                Twitter-Datenschutzbestimmungen. Diese finden Sie unter: <a
                                    href="https://twitter.com/de/tos">https://twitter.com/de/tos</a> und <a
                                    href="https://twitter.com/de/privacy">https://twitter.com/de/privacy</a>.</span></p>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    Registrierung mit GitHub</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Statt einer direkten Registrierung auf unserer Website können
                                Sie sich mit
                                GitHub registrieren. Anbieter dieses Dienstes ist die GitHub, Inc, 88 Colin P Kelly Jr
                                St, San
                                Francisco, CA 94107, USA.</span></p>
                        
                        <p><span style="font-size: 13px;">Wenn Sie sich für die Registrierung mit GitHub entscheiden,
                                werden Sie
                                automatisch auf die Plattform von GitHub weitergeleitet. Dort können Sie sich mit Ihren
                                Nutzungsdaten
                                anmelden. Dadurch wird Ihr GitHub-Profil mit unserer Website bzw. unseren Diensten
                                verknüpft. Durch
                                diese Verknüpfung erhalten wir Zugriff auf Ihre bei GitHub hinterlegten Daten. Dies sind
                                vor
                                allem:</span></p>
                        <ul>
                            <li><span style="font-size: 13px;">GitHub-Name</span></li>
                            <li><span style="font-size: 13px;">GitHub-Profilbild</span></li>
                            <li><span style="font-size: 13px;">bei GitHub hinterlegte E-Mail-Adresse</span></li>
                            <li><span style="font-size: 13px;">GitHub-ID</span></li>
                            <li><span style="font-size: 13px;">Geburtstag</span></li>
                            <li><span style="font-size: 13px;">Geschlecht</span></li>
                            <li><span style="font-size: 13px;">Land</span></li>
                        </ul>
                        <p><span style="font-size: 13px;">Diese Daten werden zur Einrichtung, Bereitstellung und
                                Personalisierung Ihres
                                Accounts genutzt.</span></p>
                        
                        <p><span style="font-size: 13px;">Die Registrierung mit GitHub und die damit verbundenen
                                Datenverarbeitungsvorgänge erfolgen auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit.
                                a DSGVO). Diese
                                Einwilligung können Sie jederzeit mit Wirkung für die Zukunft widerrufen.</span></p>
                        
                        <p><span style="font-size: 13px;">Weitere Informationen finden Sie in den
                                GitHub-Nutzungsbedingungen und den
                                GitHub-Datenschutzbestimmungen. Diese finden Sie unter: <a
                                    href="https://help.github.com/articles/github-terms-of-service/">https://help.github.com/articles/github-terms-of-service/</a>
                                und <a
                                    href="https://help.github.com/articles/github-privacy-statement/">https://help.github.com/articles/github-privacy-statement/</a>.</span>
                        </p>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    Verarbeiten von Daten (Kunden- und Vertragsdaten)</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Wir erheben, verarbeiten und nutzen personenbezogene Daten
                                nur, soweit sie für
                                die Begründung, inhaltliche Ausgestaltung oder Änderung des Rechtsverhältnisses
                                erforderlich sind
                                (Bestandsdaten). Dies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der die
                                Verarbeitung von
                                Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
                                Personenbezogene Daten
                                über die Inanspruchnahme unserer Internetseiten (Nutzungsdaten) erheben, verarbeiten und
                                nutzen wir nur,
                                soweit dies erforderlich ist, um dem Nutzer die Inanspruchnahme des Dienstes zu
                                ermöglichen oder
                                abzurechnen.</span></p>
                        
                        <p><span style="font-size: 13px;">Die erhobenen Kundendaten werden nach Abschluss des Auftrags
                                oder Beendigung
                                der Geschäftsbeziehung gelöscht. Gesetzliche Aufbewahrungsfristen bleiben
                                unberührt.</span></p>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    Datenübermittlung bei Vertragsschluss für Dienstleistungen und digitale
                                    Inhalte</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Wir übermitteln personenbezogene Daten an Dritte nur dann,
                                wenn dies im Rahmen
                                der Vertragsabwicklung notwendig ist, etwa an das mit der Zahlungsabwicklung beauftragte
                                Kreditinstitut.</span></p>
                        
                        <p><span style="font-size: 13px;">Eine weitergehende Übermittlung der Daten erfolgt nicht bzw.
                                nur dann, wenn
                                Sie der Übermittlung ausdrücklich zugestimmt haben. Eine Weitergabe Ihrer Daten an
                                Dritte ohne
                                ausdrückliche Einwilligung, etwa zu Zwecken der Werbung, erfolgt nicht.</span></p>
                        
                        <p><span style="font-size: 13px;">Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b
                                DSGVO, der die
                                Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen
                                gestattet.</span>
                        </p>
                        <h2>
                            <strong><span style="font-size: 12pt;" class="text-gold-light">
                                    4. Newsletter</span></strong><span style="font-size: 13px;"><strong></strong></span>
                        </h2>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    Newsletterdaten</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Wenn Sie den auf der Website angebotenen Newsletter beziehen
                                möchten,
                                benötigen wir von Ihnen eine E-Mail-Adresse sowie Informationen, welche uns die
                                Überprüfung gestatten,
                                dass Sie der Inhaber der angegebenen E-Mail-Adresse sind und mit dem Empfang des
                                Newsletters
                                einverstanden sind. Weitere Daten werden nicht bzw. nur auf freiwilliger Basis erhoben.
                                Diese Daten
                                verwenden wir ausschließlich für den Versand der angeforderten Informationen und geben
                                diese nicht an
                                Dritte weiter.</span></p>
                        
                        <p><span style="font-size: 13px;">Die Verarbeitung der in das Newsletteranmeldeformular
                                eingegebenen Daten
                                erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).
                                Die erteilte
                                Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum
                                Versand des
                                Newsletters können Sie jederzeit widerrufen, etwa über den "Austragen"-Link im
                                Newsletter. Die
                                Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf
                                unberührt.</span>
                        </p>
                        
                        <p><span style="font-size: 13px;">Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns
                                hinterlegten Daten
                                werden von uns bis zu Ihrer Austragung aus dem Newsletter gespeichert und nach der
                                Abbestellung des
                                Newsletters gelöscht. Daten, die zu anderen Zwecken bei uns gespeichert wurden bleiben
                                hiervon
                                unberührt.</span></p>
                        <h2>
                            <span style="font-size: 12pt;" class="text-gold-light"><strong>5. Plugins und Tools</strong></span>
                        </h2>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    YouTube</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Unsere Website nutzt Plugins der von Google betriebenen Seite
                                YouTube.
                                Betreiber der Seiten ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066,
                                USA.</span></p>
                        
                        <p><span style="font-size: 13px;">Wenn Sie eine unserer mit einem YouTube-Plugin ausgestatteten
                                Seiten besuchen,
                                wird eine Verbindung zu den Servern von YouTube hergestellt. Dabei wird dem
                                YouTube-Server mitgeteilt,
                                welche unserer Seiten Sie besucht haben.</span></p>
                        
                        <p><span style="font-size: 13px;">Des Weiteren kann Youtube verschiedene Cookies auf Ihrem
                                Endgerät speichern.
                                Mit Hilfe dieser Cookies kann Youtube Informationen über Besucher unserer Website
                                erhalten. Diese
                                Informationen werden u. a. verwendet, um Videostatistiken zu erfassen, die
                                Anwenderfreundlichkeit zu
                                verbessern und Betrugsversuchen vorzubeugen. Die Cookies verbleiben auf Ihrem Endgerät,
                                bis Sie sie
                                löschen.</span></p>
                        
                        <p><span style="font-size: 13px;">Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen
                                Sie YouTube,
                                Ihr Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen. Dies können Sie
                                verhindern, indem Sie
                                sich aus Ihrem YouTube-Account ausloggen.</span></p>
                        
                        <p><span style="font-size: 13px;">Die Nutzung von YouTube erfolgt im Interesse einer
                                ansprechenden Darstellung
                                unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs.
                                1 lit. f DSGVO
                                dar.</span></p>
                        
                        <p><span style="font-size: 13px;">Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in
                                der
                                Datenschutzerklärung von YouTube unter: <a
                                    href="https://policies.google.com/privacy?hl=de">https://policies.google.com/privacy?hl=de</a>.</span>
                        </p>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    Vimeo</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Unsere Website nutzt Plugins des Videoportals Vimeo. Anbieter
                                ist die Vimeo
                                Inc., 555 West 18th Street, New York, New York 10011, USA.</span></p>
                        
                        <p><span style="font-size: 13px;">Wenn Sie eine unserer mit einem Vimeo-Plugin ausgestatteten
                                Seiten besuchen,
                                wird eine Verbindung zu den Servern von Vimeo hergestellt. Dabei wird dem Vimeo-Server
                                mitgeteilt,
                                welche unserer Seiten Sie besucht haben. Zudem erlangt Vimeo Ihre IP-Adresse. Dies gilt
                                auch dann, wenn
                                Sie nicht bei Vimeo eingeloggt sind oder keinen Account bei Vimeo besitzen. Die von
                                Vimeo erfassten
                                Informationen werden an den Vimeo-Server in den USA übermittelt.</span></p>
                        
                        <p><span style="font-size: 13px;">Wenn Sie in Ihrem Vimeo-Account eingeloggt sind, ermöglichen
                                Sie Vimeo, Ihr
                                Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen. Dies können Sie verhindern,
                                indem Sie sich
                                aus Ihrem Vimeo-Account ausloggen.</span></p>
                        
                        <p><span style="font-size: 13px;">Die Nutzung von Vimeo erfolgt im Interesse einer ansprechenden
                                Darstellung
                                unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne des Art. 6 Abs.
                                1 lit. f DSGVO
                                dar.</span></p>
                        
                        <p><span style="font-size: 13px;">Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in
                                der
                                Datenschutzerklärung von Vimeo unter: <a
                                    href="https://vimeo.com/privacy">https://vimeo.com/privacy</a>.</span></p>
                        
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    Google Adsense</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Diese Website verwendet Google AdSense. Es handelt sich
                                hierbei um einen
                                Dienst der Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, <a
                                    href="https://www.datenschutz.org/usa/">USA</a>, zur Einbindung von Werbeanzeigen.
                                Google AdSense
                                verwendet <a href="https://www.datenschutz.org/cookies/">Cookies</a>. Dies sind Dateien,
                                durch deren
                                Speicherung auf Ihrem PC Google die Daten Ihrer Benutzung unserer Website analysieren
                                kann. Zudem werden
                                bei Google AdSense zusätzlich Web Beacons verwendet, nicht sichtbare Grafiken, die es
                                Google
                                ermöglichen, Klicks auf dieser Website, den Verkehr auf dieser und ähnliche
                                Informationen zu
                                analysieren.</span></p>
                        
                        <p><span style="font-size: 13px;">Die über Cookies und Web Beacons erhaltenen Informationen,
                                Ihre <a href="https://www.datenschutz.org/ip-adresse-datenschutz/">IP-Adresse</a> sowie
                                die Auslieferung von
                                Werbeformaten werden an einen Server von Google mit Standort in den USA übermittelt und
                                dort
                                gespeichert. Google wird diese gesammelten Informationen möglicherweise an Dritte
                                weitergeben, wenn dies
                                gesetzlich erforderlich ist oder Google gegenüber Dritten die Datenverarbeitung in
                                Auftrag gibt.
                                Allerdings wird Google Ihre IP-Adresse zusammen mit den anderen gespeicherten Daten
                                zusammenführen.</span></p>
                        
                        <p><span style="font-size: 13px;">Durch entsprechende Einstellungen an Ihrem Internetbrowser
                                können Sie
                                verhindern, dass die genannten Cookies auf Ihrem PC gespeichert werden. Dadurch besteht
                                jedoch die
                                Möglichkeit, dass die Inhalte dieser Website nicht mehr in gleichem Umfang genutzt
                                werden können. Durch
                                die Nutzung dieser Website willigen Sie in die Bearbeitung der zu Ihrer Person erhobenen
                                Daten durch
                                Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck
                                ein.</span></p>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    Google Web Fonts</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Diese Seite nutzt zur einheitlichen Darstellung von
                                Schriftarten so genannte
                                Web Fonts, die von Google bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr
                                Browser die benötigten
                                Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.</span>
                        </p>
                        
                        <p><span style="font-size: 13px;">Zu diesem Zweck muss der von Ihnen verwendete Browser
                                Verbindung zu den
                                Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis darüber, dass über Ihre
                                IP-Adresse
                                unsere Website aufgerufen wurde. Die Nutzung von Google Web Fonts erfolgt im Interesse
                                einer
                                einheitlichen und ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein
                                berechtigtes
                                Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.</span></p>
                        
                        <p><span style="font-size: 13px;">Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine
                                Standardschrift von
                                Ihrem Computer genutzt.</span></p>
                        
                        <p><span style="font-size: 13px;">Weitere Informationen zu Google Web Fonts finden Sie unter <a
                                    href="https://developers.google.com/fonts/faq">https://developers.google.com/fonts/faq</a>
                                und in
                                der Datenschutzerklärung von Google: <a
                                    href="https://policies.google.com/privacy?hl=de">https://policies.google.com/privacy?hl=de</a>.</span>
                        </p>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    Google Maps</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Diese Seite nutzt über eine API den Kartendienst Google Maps.
                                Anbieter ist die
                                Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.</span></p>
                        
                        <p><span style="font-size: 13px;">Zur Nutzung der Funktionen von Google Maps ist es notwendig,
                                Ihre IP Adresse
                                zu speichern. Diese Informationen werden in der Regel an einen Server von Google in den
                                USA übertragen
                                und dort gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf diese
                                Datenübertragung.</span>
                        </p>
                        
                        <p><span style="font-size: 13px;">Die Nutzung von Google Maps erfolgt im Interesse einer
                                ansprechenden
                                Darstellung unserer Online-Angebote und an einer leichten Auffindbarkeit der von uns auf
                                der Website
                                angegebenen Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit.
                                f DSGVO
                                dar.</span></p>
                        
                        <p><span style="font-size: 13px;">Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in
                                der
                                Datenschutzerklärung von Google: <a
                                    href="https://policies.google.com/privacy?hl=de">https://policies.google.com/privacy?hl=de</a>.</span>
                        </p>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    Google reCAPTCHA</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Wir nutzen “Google reCAPTCHA” (im Folgenden “reCAPTCHA”) auf
                                unseren Websites.
                                Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA
                                (“Google”).</span>
                        </p>
                        
                        <p><span style="font-size: 13px;">Mit reCAPTCHA soll überprüft werden, ob die Dateneingabe auf
                                unseren Websites
                                (z.B. in einem Kontaktformular) durch einen Menschen oder durch ein automatisiertes
                                Programm erfolgt.
                                Hierzu analysiert reCAPTCHA das Verhalten des Websitebesuchers anhand verschiedener
                                Merkmale. Diese
                                Analyse beginnt automatisch, sobald der Websitebesucher die Website betritt. Zur Analyse
                                wertet
                                reCAPTCHA verschiedene Informationen aus (z.B. IP-Adresse, Verweildauer des
                                Websitebesuchers auf der
                                Website oder vom Nutzer getätigte Mausbewegungen). Die bei der Analyse erfassten Daten
                                werden an Google
                                weitergeleitet.</span></p>
                        
                        <p><span style="font-size: 13px;">Die reCAPTCHA-Analysen laufen vollständig im Hintergrund.
                                Websitebesucher
                                werden nicht darauf hingewiesen, dass eine Analyse stattfindet.</span></p>
                        
                        <p><span style="font-size: 13px;">Die Datenverarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1
                                lit. f DSGVO.
                                Der Websitebetreiber hat ein berechtigtes Interesse daran, seine Webangebote vor
                                missbräuchlicher
                                automatisierter Ausspähung und vor SPAM zu schützen.</span></p>
                        
                        <p><span style="font-size: 13px;">Weitere Informationen zu Google reCAPTCHA sowie die
                                Datenschutzerklärung von
                                Google entnehmen Sie folgenden Links: <a
                                    href="https://policies.google.com/privacy?hl=de">https://policies.google.com/privacy?hl=de</a>
                                und
                                <a
                                    href="https://www.google.com/recaptcha/intro/android.html">https://www.google.com/recaptcha/intro/android.html</a>.</span>
                        </p>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    SoundCloud</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Auf unseren Seiten können Plugins des sozialen Netzwerks
                                SoundCloud
                                (SoundCloud Limited, Berners House, 47-48 Berners Street, London W1T 3NF,
                                Großbritannien.) integriert
                                sein. Die SoundCloud-Plugins erkennen Sie an dem SoundCloud-Logo auf den betroffenen
                                Seiten.</span></p>
                        
                        <p><span style="font-size: 13px;">Wenn Sie unsere Seiten besuchen, wird nach Aktivierung des
                                Plugin eine direkte
                                Verbindung zwischen Ihrem Browser und dem SoundCloud-Server hergestellt. SoundCloud
                                erhält dadurch die
                                Information, dass Sie mit Ihrer IP-Adresse unsere Seite besucht haben. Wenn Sie den
                                “Like-Button” oder
                                “Share-Button” anklicken während Sie in Ihrem SoundCloud- Benutzerkonto eingeloggt sind,
                                können Sie die
                                Inhalte unserer Seiten mit Ihrem SoundCloud-Profil verlinken und/oder teilen. Dadurch
                                kann SoundCloud
                                Ihrem Benutzerkonto den Besuch unserer Seiten zuordnen. Wir weisen darauf hin, dass wir
                                als Anbieter der
                                Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch
                                SoundCloud
                                erhalten.</span></p>
                        
                        <p><span style="font-size: 13px;">Die Nutzung von SoundCloud erfolgt auf Grundlage des Art. 6
                                Abs. 1 lit. f
                                DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an einer möglichst
                                umfangreichen Sichtbarkeit
                                in den Sozialen Medien.</span></p>
                        
                        <p><span style="font-size: 13px;">Weitere Informationen hierzu finden Sie in der
                                Datenschutzerklärung von
                                SoundCloud unter: <a
                                    href="https://soundcloud.com/pages/privacy">https://soundcloud.com/pages/privacy</a>.</span>
                        </p>
                        
                        <p><span style="font-size: 13px;">Wenn Sie nicht wünschen, dass SoundCloud den Besuch unserer
                                Seiten Ihrem
                                SoundCloud- Benutzerkonto zuordnet, loggen Sie sich bitte aus Ihrem
                                SoundCloud-Benutzerkonto aus bevor
                                Sie Inhalte des SoundCloud-Plugins aktivieren.</span></p>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    Veoh</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Unsere Website nutzt Plugins des Videoportals Veoh. Anbieter
                                ist die FC2, 4730
                                South Fort Apache Road, Suite 300, Las Vegas, NV 89147, USA.</span></p>
                        
                        <p><span style="font-size: 13px;">Wenn Sie eine unserer mit einem Veoh-Plugin ausgestatteten
                                Seiten besuchen,
                                wird eine Verbindung zu den Servern von Veoh hergestellt. Dabei wird dem Veoh-Server
                                mitgeteilt, welche
                                unserer Seiten Sie besucht haben. Zudem erlangt Veoh Ihre IP-Adresse. Dies gilt auch
                                dann, wenn Sie
                                nicht bei Veoh eingeloggt sind oder keinen Account bei Veoh besitzen. Die von Veoh
                                erfassten
                                Informationen werden an den Veoh-Server in den USA übermittelt.</span></p>
                        
                        <p><span style="font-size: 13px;">Wenn Sie in Ihrem Veoh-Account eingeloggt sind, ermöglichen
                                Sie Veoh, Ihr
                                Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen. Dies können Sie verhindern,
                                indem Sie sich
                                aus Ihrem Veoh-Account ausloggen.</span></p>
                        
                        <p><span style="font-size: 13px;">Die Nutzung von Veoh erfolgt im Interesse einer ansprechenden
                                Darstellung
                                unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne des Art. 6 Abs.
                                1 lit. f DSGVO
                                dar.</span></p>
                        
                        <p><span style="font-size: 13px;">Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in
                                der
                                Datenschutzerklärung von Veoh unter: <a
                                    href="https://www.veoh.com/corporate/privacy-policy">https://www.veoh.com/corporate/privacy-policy</a>.</span>
                        </p>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    Dailymotion</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Unsere Website nutzt Plugins des Videoportals Dailymotion.
                                Anbieter ist
                                Dailymotion, 140 boulevard Malesherbes, 75017 Paris, Frankreich.</span></p>
                        
                        <p><span style="font-size: 13px;">Wenn Sie eine unserer mit einem Dailymotion-Plugin
                                ausgestatteten Seiten
                                besuchen, wird eine Verbindung zu den Servern von Dailymotion hergestellt. Dabei wird
                                dem
                                Dailymotion-Server mitgeteilt, welche unserer Seiten Sie besucht haben. Zudem erlangt
                                Dailymotion Ihre
                                IP-Adresse. Dies gilt auch dann, wenn Sie nicht bei Dailymotion eingeloggt sind oder
                                keinen Account bei
                                Dailymotion besitzen.</span></p>
                        
                        <p><span style="font-size: 13px;">Wenn Sie in Ihrem Dailymotion-Account eingeloggt sind,
                                ermöglichen Sie
                                Dailymotion, Ihr Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen. Dies können
                                Sie verhindern,
                                indem Sie sich aus Ihrem Dailymotion-Account ausloggen.</span></p>
                        
                        <p><span style="font-size: 13px;">Die Nutzung von Dailymotion erfolgt im Interesse einer
                                ansprechenden
                                Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne des
                                Art. 6 Abs. 1
                                lit. f DSGVO dar.</span></p>
                        
                        <p><span style="font-size: 13px;">Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in
                                der
                                Datenschutzerklärung von Dailymotion unter: <a
                                    href="https://www.dailymotion.com/legal/privacy">https://www.dailymotion.com/legal/privacy</a>.</span>
                        </p>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    GitHub</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Unsere Website nutzt Plugins des Online-Dienstes GitHub.
                                Anbieter ist GitHub,
                                Inc, 88 Colin P Kelly Jr St, San Francisco, CA 94107, USA.</span></p>
                        
                        <p><span style="font-size: 13px;">Wenn Sie eine unserer mit einem GitHub-Plugin ausgestatteten
                                Seiten besuchen,
                                wird eine Verbindung zu den Servern von GitHub hergestellt. Dabei wird dem GitHub-Server
                                mitgeteilt,
                                welche unserer Seiten Sie besucht haben. Zudem erlangt GitHub Ihre IP-Adresse. Dies gilt
                                auch dann, wenn
                                Sie nicht bei GitHub eingeloggt sind oder keinen Account bei GitHub besitzen. Die von
                                GitHub erfassten
                                Informationen werden an den GitHub-Server in den USA übermittelt.</span></p>
                        
                        <p><span style="font-size: 13px;">Wenn Sie in Ihrem GitHub-Account eingeloggt sind, ermöglichen
                                Sie GitHub, Ihr
                                Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen. Dies können Sie verhindern,
                                indem Sie sich
                                aus Ihrem GitHub-Account ausloggen.</span></p>
                        
                        <p><span style="font-size: 13px;">Die Nutzung von GitHub erfolgt im Interesse einer
                                ansprechenden Darstellung
                                unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne des Art. 6 Abs.
                                1 lit. f DSGVO
                                dar.</span></p>
                        
                        <p><span style="font-size: 13px;">Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in
                                der
                                Datenschutzerklärung von GitHub unter: <a
                                    href="https://help.github.com/articles/github-privacy-statement/">https://help.github.com/articles/github-privacy-statement/</a>.</span>
                        </p>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    Spotify</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Unsere Website nutzt Plugins des Online-Dienstes Spotify.
                                Anbieter ist die
                                Spotify AB, Birger Jarlsgatan 61, 113 56 Stockholm, Schweden.</span></p>
                        
                        <p><span style="font-size: 13px;">Wenn Sie eine unserer mit einem Spotify-Plugin ausgestatteten
                                Seiten besuchen,
                                wird eine Verbindung zu den Servern von Spotify hergestellt. Dabei wird dem
                                Spotify-Server mitgeteilt,
                                welche unserer Seiten Sie besucht haben. Zudem erlangt Spotify Ihre IP-Adresse. Dies
                                gilt auch dann,
                                wenn Sie nicht bei Spotify eingeloggt sind oder keinen Account bei Spotify
                                besitzen.</span></p>
                        
                        <p><span style="font-size: 13px;">Wenn Sie in Ihrem Spotify-Account eingeloggt sind, ermöglichen
                                Sie Spotify,
                                Ihr Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen. Dies können Sie
                                verhindern, indem Sie
                                sich aus Ihrem Spotify-Account ausloggen.</span></p>
                        
                        <p><span style="font-size: 13px;">Die Nutzung von Spotify erfolgt im Interesse einer
                                ansprechenden Darstellung
                                unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne des Art. 6 Abs.
                                1 lit. f DSGVO
                                dar.</span></p>
                        
                        <p><span style="font-size: 13px;">Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in
                                der
                                Datenschutzerklärung von Spotify unter: <a
                                    href="https://www.spotify.com/de/legal/privacy-policy/">https://www.spotify.com/de/legal/privacy-policy/</a>.</span>
                        </p>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    Instagram</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Unsere Website nutzt Plugins des Online-Dienstes Instagram.
                                Anbieter ist
                                Instagram Inc., 1601 Willow Road, Menlo Park, CA 94025, USA.</span></p>
                        
                        <p><span style="font-size: 13px;">Wenn Sie eine unserer mit einem Instagram-Plugin
                                ausgestatteten Seiten
                                besuchen, wird eine Verbindung zu den Servern von Instagram hergestellt. Dabei wird dem
                                Instagram-Server
                                mitgeteilt, welche unserer Seiten Sie besucht haben. Zudem erlangt Instagram Ihre
                                IP-Adresse. Dies gilt
                                auch dann, wenn Sie nicht bei Instagram eingeloggt sind oder keinen Account bei
                                Instagram besitzen. Die
                                von Instagram erfassten Informationen werden an den Instagram-Server in den USA
                                übermittelt.</span></p>
                        
                        <p><span style="font-size: 13px;">Wenn Sie in Ihrem Instagram-Account eingeloggt sind,
                                ermöglichen Sie
                                Instagram, Ihr Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen. Dies können
                                Sie verhindern,
                                indem Sie sich aus Ihrem Instagram-Account ausloggen.</span></p>
                        
                        <p><span style="font-size: 13px;">Die Nutzung von Instagram erfolgt im Interesse einer
                                ansprechenden Darstellung
                                unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne des Art. 6 Abs.
                                1 lit. f DSGVO
                                dar.</span></p>
                        
                        <p><span style="font-size: 13px;">Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in
                                der
                                Datenschutzerklärung von Instagram unter: <a
                                    href="https://instagram.com/about/legal/privacy/">https://instagram.com/about/legal/privacy/</a>.</span>
                        </p>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    Imgur</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Unsere Website nutzt Plugins des Online-Dienstes Imgur.
                                Anbieter ist Imgur,
                                Inc., 415 Jackson Street, 2nd Floor, Suite 200, San Francisco, CA 94111, USA.</span></p>
                        
                        <p><span style="font-size: 13px;">Wenn Sie eine unserer mit einem Imgur-Plugin ausgestatteten
                                Seiten besuchen,
                                wird eine Verbindung zu den Servern von Imgur hergestellt. Dabei wird dem Imgur-Server
                                mitgeteilt,
                                welche unserer Seiten Sie besucht haben. Zudem erlangt Imgur Ihre IP-Adresse. Dies gilt
                                auch dann, wenn
                                Sie nicht bei Imgur eingeloggt sind oder keinen Account bei Imgur besitzen. Die von
                                Imgur erfassten
                                Informationen werden an den Imgur-Server in den USA übermittelt.</span></p>
                        
                        <p><span style="font-size: 13px;">Wenn Sie in Ihrem Imgur-Account eingeloggt sind, ermöglichen
                                Sie Imgur, Ihr
                                Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen. Dies können Sie verhindern,
                                indem Sie sich
                                aus Ihrem Imgur-Account ausloggen.</span></p>
                        
                        <p><span style="font-size: 13px;">Die Nutzung von Imgur erfolgt im Interesse einer ansprechenden
                                Darstellung
                                unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne des Art. 6 Abs.
                                1 lit. f DSGVO
                                dar.</span></p>
                        
                        <p><span style="font-size: 13px;">Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in
                                der
                                Datenschutzerklärung von Imgur unter: <a
                                    href="https://imgur.com/privacy">https://imgur.com/privacy</a>.</span></p>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    Twitch</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Unsere Website nutzt Plugins des Online-Dienstes Twitch.
                                Anbieter ist Twitch
                                Interactive, Inc., 225 Bush Street, 6th Floor, San Francisco, CA 94104, USA.</span></p>
                        
                        <p><span style="font-size: 13px;">Wenn Sie eine unserer mit einem Twitch-Plugin ausgestatteten
                                Seiten besuchen,
                                wird eine Verbindung zu den Servern von Twitch hergestellt. Dabei wird dem Twitch-Server
                                mitgeteilt,
                                welche unserer Seiten Sie besucht haben. Zudem erlangt Twitch Ihre IP-Adresse. Dies gilt
                                auch dann, wenn
                                Sie nicht bei Twitch eingeloggt sind oder keinen Account bei Twitch besitzen. Die von
                                Twitch erfassten
                                Informationen werden an den Twitch-Server in den USA übermittelt.</span></p>
                        
                        <p><span style="font-size: 13px;">Wenn Sie in Ihrem Twitch-Account eingeloggt sind, ermöglichen
                                Sie Twitch, Ihr
                                Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen. Dies können Sie verhindern,
                                indem Sie sich
                                aus Ihrem Twitch-Account ausloggen.</span></p>
                        
                        <p><span style="font-size: 13px;">Die Nutzung von Twitch erfolgt im Interesse einer
                                ansprechenden Darstellung
                                unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne des Art. 6 Abs.
                                1 lit. f DSGVO
                                dar.</span></p>
                        
                        <p><span style="font-size: 13px;">Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in
                                der
                                Datenschutzerklärung von Twitch unter: <a
                                    href="https://www.twitch.tv/p/legal/privacy-policy/">https://www.twitch.tv/p/legal/privacy-policy/</a>.</span>
                        </p>
                        <h3>
                            <span style="font-size: 13px;"><strong>
                                    Twitter</strong></span>
                        </h3>
                        <p><span style="font-size: 13px;">Unsere Website nutzt Plugins des Online-Dienstes Twitter.
                                Anbieter ist Twitter
                                Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA.</span></p>
                        
                        <p><span style="font-size: 13px;">Wenn Sie eine unserer mit einem Twitter-Plugin ausgestatteten
                                Seiten besuchen,
                                wird eine Verbindung zu den Servern von Twitter hergestellt. Dabei wird dem
                                Twitter-Server mitgeteilt,
                                welche unserer Seiten Sie besucht haben. Zudem erlangt Twitter Ihre IP-Adresse. Dies
                                gilt auch dann,
                                wenn Sie nicht bei Twitter eingeloggt sind oder keinen Account bei Twitter besitzen. Die
                                von Twitter
                                erfassten Informationen werden an den Twitter-Server in den USA übermittelt.</span></p>
                        
                        <p><span style="font-size: 13px;">Wenn Sie in Ihrem Twitter-Account eingeloggt sind, ermöglichen
                                Sie Twitter,
                                Ihr Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen. Dies können Sie
                                verhindern, indem Sie
                                sich aus Ihrem Twitter-Account ausloggen.</span></p>
                        
                        <p><span style="font-size: 13px;">Die Nutzung von Twitter erfolgt im Interesse einer
                                ansprechenden Darstellung
                                unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne des Art. 6 Abs.
                                1 lit. f DSGVO
                                dar.</span></p>
                        
                        <p><span style="font-size: 13px;">Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in
                                der
                                Datenschutzerklärung von Twitter unter: <a
                                    href="https://twitter.com/de/privacy">https://twitter.com/de/privacy</a>.</span></p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import Windows from "../windows.js"

    export default Windows.register({
        name: "privacypolicy",
        props: {
            data: String
        },
        data() {
            return {
            }
        },
        methods: { 
        },
        mounted: function () {
        },
        destroyed: function () {
        }
    })
</script>

<style>

</style>