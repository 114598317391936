import global from "./global"

if (global.components == undefined) {
    global.components = {}
}

class Component {

    static register(component) {
        component.beforeMount = function() {
            global.components[component.name] = this
        }
        component.beforeDestroy = function() {
            delete global.components[component.name]
        }
        if (component.methods == undefined) {
            component.methods = {}
        }
        return component
    }
}

export default Component
