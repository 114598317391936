import Components from "./components"

class Windows extends Components {
  static register(component) {
    component = Components.register(component)
    component.methods.dismiss = function() {
      this.$parent.show(null)
    }
    return component
  }
}

export default Windows
