<template>
<div>
        <section class="hero-privacy py-5">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-8">
                        <h2 class="text-center text-light">Impressum</h2>
                    </div>
                    <div class="col-md-4">
                        <img :src="require('@/assets/logo.png')" alt="Logo" class="img-fluid rounded-circle">
                    </div>
                </div>
            </div>
        </section>

        <section class="privacy py-5 bg-dark">
            <div class="container">
                <div class="row">
                    <div>
                        <h2><span style="font-size: 12pt;" class="text-gold-light"><strong>Allgemeine Informationen</strong></span></h2>
                        <hr>
                        <h3><span style="font-size: 13px;"><strong>Name und Anschrift</strong></span></h3>
                        <span style="font-size: 13px;">VegaByte</span>
                        <h3><span style="font-size: 13px;"><strong>E-Mail-Adresse</strong></span></h3>
                        <span style="font-size: 13px;"><a href="mailto:contact@vegabyte.de">contact@vegabyte.de</a></span>
                        <p>&nbsp;</p>
                        <h2><span style="font-size: 12pt;" class="text-gold-light"><strong>Weitere Informationen</strong></span></h2>
                        <hr>
                        <h3><span style="font-size: 13px;"><strong>Gebrauch der Kontaktdaten</strong></span></h3>
                        <p><span style="font-size: 13px;">Die Kontaktdaten sind keine Daten für den Support. Bei Missachtung, bzw. Spam/Phising behalten wir uns vor, die Email zu blockieren.</span></p>
                        <h3><span style="font-size: 13px;"><strong>Verbraucher-Streitschlichtung</strong></span></h3>
                        <p><span style="font-size: 13px;">Die EU-Kommission hat eine Internetplattform zur Online-Beilegung von Streitigkeiten betreffend vertraglicher Verpflichtungen aus Online-Verträgen geschaffen (OS-Plattform). Sie können die OS Plattform unter dem folgenden Link erreichen: <a href="http://ec.europa.eu/consumers/odr/">Online Dispute Resolution</a>. Wir sind nicht bereit und nicht verpflichtet an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</span></p>
                        <h3><span style="font-size: 13px;"><strong>Haftung für Links</strong></span></h3>
                        <p><span style="font-size: 13px;">Externe Links unserer Internetseite führen zu Inhalten fremder Anbieter. Für diese Inhalte ist allein der jeweilige Anbieter verantwortlich. Bei bekannt werden von Rechtsverletzungen werden diese Links umgehend entfernt.</span></p>
                        <h3><span style="font-size: 13px;"><strong>Urheberrechtshinweis</strong></span></h3>
                        <p><span style="font-size: 13px;">Die durch uns erstellten Inhalte und Werke auf dieser Website unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Soweit die Inhalte auf dieser Seite nicht von uns selbst erstellt wurden, werden Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, wird um einen entsprechenden Hinweis gebeten. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</span></p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Windows from "../windows.js"

    export default Windows.register({
        name: "impressum",
        props: {
            data: String
        },
        data() {
            return {
            }
        },
        methods: { 
        },
        mounted: function () {
        },
        destroyed: function () {
        }
    })
</script>

<style>

</style>