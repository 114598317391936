<template>
    <div>
        <section class="content bg-dark" style="padding: 20px 0px;">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-4">
                        <div class="card card-primary card-outline">
                            <div class="card-body box-profile">
                                <div class="text-center">
                                    <img class="profile-user-img img-fluid img-circle profilepicture"
                                        :src="require('@/assets/workers/florianG.jpg')" alt="User profile picture">
                                </div>
                                <h3 class="profile-username text-center">Florian Gäb</h3>
                                <p class="text-muted text-center">Brandbuilding, Marketing und CyberSecurity </p>
                                <ul class="list-group list-group-unbordered mb-3">
                                    <li class="list-group-item">
                                        <b><i class="fa-solid fa-calendar mr-1"></i> Alter</b></br> <span
                                            class="float-right">28 Jahre</span>
                                    </li>
                                    <li class="list-group-item">
                                        <b><i class="fa-solid fa-computer mr-1"></i> Ich beschäftige mich
                                            seit</b></br> <span class="float-right">2013, mit dem Aufbau der Webauftritte von Unternehmen und deren Vermarktung</span>
                                    </li>
                                    <li class="list-group-item">
                                        <b><i class="fa-solid fa-timer mr-1"></i> Hobbys</b></br><span
                                            class="float-right">Mit dem Hund und der Familie Wanderungen in der Natur genießen, Kraftsport und Oldtimer der 70er und 80er.</span>
                                    </li>
                                    <li class="list-group-item">
                                        <b><i class="fa-solid fa-comment mr-1"></i> Sonstiges</b></br><span
                                            class="float-right">Führerschein der Klasse B.</span>
                                    </li>
                                </ul>
                            </div>

                        </div>


                        <div class="card card-primary" style="margin-top: 10px;">
                            <div class="card-header">
                                <h3 class="card-title">Über mich</h3>
                            </div>

                            <div class="card-body">
                                <strong><i class="fas fa-book mr-1"></i> Bildung</strong>
                                <p class="text-muted">
                                    - Mehrere Jahre berufliche Praxiserfahrung im Kunden und Anwendersupport sowie Projektmanagement </br>
                                    - ITIL Zertifizierung
                                    - Mittlere Reife mit der Schwerpunkt Technik 
                                    - Berufliches Gymnasium mit den Leistungskursen Informatik und Erdkunde
                                </p>
                                <hr>
                                <strong><i class="fas fa-map-marker-alt mr-1"></i> Wohnort</strong>
                                <p class="text-muted">Nordrhein-Westfalen, Deutschland</p>
                                <hr>
                                <strong><i class="fas fa-pencil-alt mr-1"></i> Skills</strong>
                                <p class="text-muted">
                                    Redegewandheit, Kreativität, Teamfähigkeit, unternehmerisches Denken
                                </p>
                                <hr>
                                <strong><i class="fas fa-pencil-alt mr-1"></i> Technische Skills</strong>
                                <p class="text-muted">
                                    Vertrieb und Marketing, C#, Java, Prozessplanung, Webdesign, P2P Encryption, CyberSecurity
                                </p>
                                <p class="text-muted">
                                    Sicher im Umgang mit Windows und Unix, sowie der Nutzung verschiedener Buchhaltungs, Marketing und Vertriebs Tools.
                                </p>
                            </div>

                        </div>

                    </div>

                    <div class="col-md-8">
                        <div class="card">
                            <div class="card-body">
                                <div class="timeline">
                                    <!-- timeline time label -->
                                    <div class="time-label"> <span class="text-bg-danger">2024</span> </div>
                                    <!-- /.timeline-label -->
                                     <!-- timeline item -->
                                    <div> <i class="timeline-icon bi bi-person text-bg-success"></i>
                                        <div class="timeline-item"> <span class="time"> <i class="bi bi-clock-fill"></i>
                                                Anfang 2024
                                            </span>
                                            <h3 class="timeline-header no-border"> Gründung Vegabyte Webdesign und Hosting</br>
                                            Anfang diesen Jahres habe ich und mein langjähriger Partner in verschiedenen Projekten uns dazu entschieden, unsere Fähgkeiten zu bündeln etwas eigenes zu schaffen!
                                            (Placeholder -> Wir sollten ein Ziel definieren weshalb wir das Unternehmen gegründet haben, was wir besser machen als die Konkurenz und welche Dienstleistungen wir anbieten wollen!)
                                            </h3>

                                        </div>
                                    </div> 
                                    <!-- END timeline item -->
                                    <!-- timeline item -->
                                    <div> <i class="timeline-icon bi bi-person text-bg-success"></i>
                                        <div class="timeline-item"> <span class="time"> <i class="bi bi-clock-fill"></i>
                                                Jahr 2022- Anfang 2024
                                            </span>
                                            <h3 class="timeline-header no-border"> IT Servicecoordination und Projektmanagement</br>
                                            In meiner letzten Position als Servicecoordinator bin ich für die Organisation und technische Qualitätssicherung von IT-Dienstleistungen,</br>
                                            innerhalb des Service Desk eines namenhaften Schuhherstellers verantwortlich gewesen. </br>
                                            Zu meinen Aufgaben gehörten die Abstimmung mit dem Kunden und anderen Abteilungen, das Erarbeiten von Wissensartikeln, die Einarbeitung von neuen Mitarbeitern und die Planung von verschiedenen Projekten. (zb. Migration des Exchange oder der Clients in Intune)</br>
                                            Ich leitete ein Team von IT-Fachkräften und war zuständig für die Planung, Überwachung und das Reporting von SLA´s und Projekten. </br>
                                            Dabei setzte ich stets auf gewinnorientierte sowie Ressourcen sparende Methoden und nutze Tools wie Jira, Trello, GitHub und Teams, um den Projektfortschritt effizient zu verwalten.
                                            </h3>

                                        </div>
                                    </div> 
                                    <!-- END timeline item -->
                                    <!-- timeline time label -->
                                    <div class="time-label"> <span class="text-bg-danger">2022</span> </div>
                                    <!-- /.timeline-label -->
                                    <!-- timeline item -->
                                    <div> <i class="timeline-icon bi bi-person text-bg-success"></i>
                                        <div class="timeline-item"> <span class="time"> <i class="bi bi-clock-fill"></i>
                                                Jahr 2016-2021
                                            </span>
                                            <h3 class="timeline-header no-border"> Entwicklung Konzept und der Aufbau des Webauftritt meines ersten eigenen Onlineshops: </br>
                                            Anfang	2016: Entwicklung der Webseite, Planung des Branding und der Marketingstrategien</br>
                                            Ende 	2018: Fertigstellung und Launch des Shops</br>
                                            2019 - 	2021: Aufbau des Kundenstamms und Kooperationspartner</br>
                                            Ende 	2021: Verkauf der Website, der Markenrechte und des Produktbestandtes</br>
                                            </h3>
                                        </div>
                                    </div> <!-- END timeline item -->
                                    <!-- timeline time label -->
                                    <div class="time-label"> <span class="text-bg-danger">2020</span> </div>
                                    <!-- /.timeline-label -->
                                    <!-- timeline item -->
                                    <div> <i class="timeline-icon bi bi-person text-bg-success"></i>
                                        <div class="timeline-item"> <span class="time"> <i class="bi bi-clock-fill"></i>
                                                27 Nov 2020
                                            </span>
                                            <h3 class="timeline-header no-border"> In zusammenarbeit mit einem Freund wurde
                                                ein Minecraft Projekt wieder angefangen weiterzuentwickeln. Leider wurde das 
                                                Projekt nach kurzer Zeit eingestellt, da durch den Online Shop und den Beruf, sowie die
                                                Familie die Zeit etwas knapp wurde.
                                            </h3>
                                        </div>
                                    </div> <!-- END timeline item -->
                                    <!-- timeline time label -->
                                    <div class="time-label"> <span class="text-bg-danger">2016</span> </div>
                                    <!-- /.timeline-label -->
                                    <!-- timeline item -->
                                    <div> <i class="timeline-icon bi bi-person text-bg-success"></i>
                                        <div class="timeline-item"> <span class="time"> <i class="bi bi-clock-fill"></i>
                                                Jahr 2013-2015
                                            </span>
                                            <h3 class="timeline-header no-border"> 
                                                Während der Schulzeit lag mein Fokus immer auf der IT, in meiner Freizeit habe ich viele weitere Projekte gestartet.</br>
                                                Ich habe mich besonders intensiv mit der Einrichtung von privaten Gameservern für mich und meine Freunde, sowie</br>
                                                der Einrichtung diverser Netzwerke und der Reparatur von Rechnern aus dem Bekanntenkreis beschäftigt.</br>
                                                Wärend dieser Phase habe ich bei mehreren Startups von Freunden im Webdesign, der SEO Optimierung und der Marktanalyse ausgeholfen,</br>
                                                dabei habe ich viel Erfahrung in der Etablierung einer Marke und deren Platzierung sammeln können.
                                            </h3>
                                        </div>
                                    </div> <!-- END timeline item -->
                                     <!-- timeline time label -->
                                    <div class="time-label"> <span class="text-bg-danger">2013</span> </div>
                                    <!-- /.timeline-label -->
                                    <!-- timeline item -->
                                    <div> <i class="timeline-icon bi bi-camera-film text-bg-info"></i>
                                        <div class="timeline-item"> <span class="time"> <i class="bi bi-clock-fill"></i>
                                                Wo alles begann...
                                            </span>
                                            <h3 class="timeline-header">
                                                Alles begann mit Einrichtung meines ersten Rechners, dessen Instandhaltung und der Faszination über die scheinbar grenzenlosen Möglichkeiten.</br>
                                                Ich verbrachte viele Stunden damit die Grundlagen von IT Systemen und der Anwendung in Unternehmen sowie Privathaushalten zu studieren.</br> 
                                                Das erworbene Wissen festigte ich daraufhin, indem ich kleinere Projekte innerhalb der Familie realisierte. </br> 
                                                Dazu zählte:</br>
                                                - die Planung und Einrichtung von heimischen und einem Unternehmensnetzwerk (Routereinrichtung, Firewallconfig, Einrichtung CCTV Kamera Cloud) </br> 
                                                - das Design und die Erstellung von Webauftritten (Metallbauunternehmen, Barber und Bistro)</br>
                                                - die Planung, Einrichtung und Aufbau von diversen Privatrechner (Office Rechner, Workstations und GamingPCs)</br>
                                            </h3>
                                        </div>
                                    </div> <!-- END timeline item -->
                                     <!-- timeline time label -->
                                     <div class="time-label"> <span class="text-bg-danger">2005</span> </div>
                                    <!-- /.timeline-label -->
                                    <!-- timeline item -->
                                    <div> <i class="timeline-icon bi bi-camera-film text-bg-info"></i>
                                        <div class="timeline-item"> <span class="time"> <i class="bi bi-clock-fill"></i>
                                                Kommunion
                                            </span>
                                            <h3 class="timeline-header"> 
                                                Ich bekomme meinen ersten eigenen festen Computer ohne verfügbare Netzwerkverbindung. </br>
                                                Die Computer Bild Zeitschriften wurden mein stetiger Begleiter und sorgten von dort an für neue Infos und ein regelrecht überwältigendes Angebot an Fähigkeiten die es zu meistern galt.
                                                So verbachte ich einen großen Teil meiner Zeit damit die Hardware von Computern, verschiedene Betriebsysteme sowie die Abläufe hinter deren Funktionen zu verinnerlichen.
                                            </h3>
                                        </div>
                                    </div> <!-- END timeline item -->
                                    <!-- timeline time label -->
                                    <div class="time-label"> <span class="text-bg-danger">1996</span> </div>
                                    <!-- /.timeline-label -->
                                    <!-- timeline item -->
                                    <div> <i class="timeline-icon bi bi-camera-film text-bg-info"></i>
                                        <div class="timeline-item"> <span class="time"> <i class="bi bi-clock-fill"></i>
                                                SPAWN Zeitpunkt
                                            </span>
                                            <h3 class="timeline-header"> 
                                                Das Abenteuer beginnt...
                                            </h3>
                                        </div>
                                    </div> <!-- END timeline item -->
                                    
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </section>
    </div>
</template>

<script>
    import Windows from "../windows.js"

    export default Windows.register({
        name: "florianG",
        props: {
            data: String
        },
        data() {
            return {
            }
        },
        methods: { 
            show: function (window) {
                components.Framework.show(window)
            },
        },
        mounted: function () {
        },
        destroyed: function () {
        }
    })
</script>

<style scoped>
    @import "../../style/timeline.css";
</style>